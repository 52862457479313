<template>
  <v-select
    :value="value"
    v-bind="$attrs"
    v-on="$listeners"
    color="tertiary"
    :label="$t('hr.travel.detail_type')"
    item-value="value"
    :item-text="item => $t(`hr.travel.travel_detail_type_enum.${item.text}`)"
    :items="$helpers.fromEnumToArray(TravelDetailTypeEnum)"
    :menu-props="{ offsetY: true, overflowY: true }"
    @input="$emit('input', $event)"
  />
</template>

<script>
  /**
   * @author Ali Hüseyin ŞİRELİ
   * @email ali.sireli@globme.com.tr
   * @create date 2020-12-29 08:21:25
   * @modify date 2020-12-29 08:21:25
   * @desc TravelDetailTypePicker
   */
  import { TravelDetailTypeEnum } from "../enums";
  export default {
    name: "TravelDetailTypePicker",
    inheritAttrs: false,
    props: {
      value: String
    },
    data: vm => ({
      TravelDetailTypeEnum
    })
  };
</script>

<style></style>
